<template>
    <div>
        <el-card shadow="hover" class="card">
            <el-row type="flex">
                <el-card shadow="hover" class="coupon-card">
                    <div style="font-weight: bold">{{$t("m.coupon.voucher")}}</div>
                    <div style="font-size: 13px;color: gray">{{$t("m.coupon.daijingquanmsg")}}</div>
                    <div style="font-size: 13px;color: gray">{{$t("m.coupon.bianyuehelikongzhi")}}</div>
                    <el-button size="small" type="primary" @click="handleAdd">{{$t("m.coupon.lijinxinjiang")}}</el-button>
                </el-card>
            </el-row>
        </el-card>
        <el-card shadow="hover" class="card">
            <el-form :inline="true" size="small">
                <el-form-item :label="$t('m.coupon.guanjiangci')">
                    <el-input v-model="FormData.keyword" :placeholder="$t('m.coupon.youhuimingcheng')">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="pageNumChanged(1)">{{$t("m.coupon.CheckFor")}}</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="Result.list" class="eltable cz-table">
                <el-table-column :label="$t('m.coupon.name')"
                                 prop="name"
                                 align="center">

                </el-table-column>
<!--                <el-table-column :label="$t('m.coupon.leixing')"-->
<!--                                 prop="type"-->
<!--                                 align="center">-->
<!--                    <template slot-scope="scope">-->
<!--                        <div v-if="scope.row.type == 0">{{$t("m.coupon.voucher")}}</div>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column :label="$t('m.coupon.IssueNumber')"
                                 prop="count"
                                 align="center">
                </el-table-column>
                <el-table-column :label="$t('m.coupon.alreadyReceived')"
                                 prop="receiveCount"
                                 align="center">
                </el-table-column>
                <el-table-column :label="$t('m.coupon.residue')"
                                 align="center">
                    <template slot-scope="scope">
                        {{scope.row.count - scope.row.receiveCount}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('m.coupon.yihexiao')"
                                 prop="useCount"
                                 align="center">
                </el-table-column>
                <el-table-column :label="$t('m.coupon.state')"
                                 align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status == 0">{{$t("m.coupon.valid")}}</div>
                        <div v-else-if="scope.row.status == 1">{{$t("m.coupon.loseEfficacy")}}</div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('m.pubilc.caozuo')"
                                 width="300"
                                 align="center">
                    <template slot-scope="scope">
                        <el-button type="text"
                                   style="margin-right:10px"
                                   class="action-button"
                                   @click="toEdit(scope.row)"
                                   size="mini">{{$t("m.coupon.xiangqing")}}
                        </el-button>
                        <el-popconfirm :title="$t('m.coupon.changtishi')"
                                       v-if="scope.row.status === 0"
                                       @confirm="updateInfo({id:scope.row.id,status:1})"
                                       width="250">
                            <el-button type="text"
                                       class="action-button"
                                       size="mini"
                                       slot="reference">{{$t("m.coupon.loseEfficacy")}}
                            </el-button>
                        </el-popconfirm>
                        <el-button type="text"
                                   v-if="scope.row.status==0&&scope.row.count - scope.row.receiveCount>0"
                                   slot="reference"
                                   class="action-button"
                                   @click="setInfo({id:scope.row.id})"
                                   size="mini">{{$t("m.coupon.fafang")}}
                        </el-button>
                        <el-popconfirm :title="$t('m.coupon.quedingshanchuma')"
                                        v-if="scope.row.receiveCount === 0"
                                       @confirm="delInfo({id:scope.row.id})"
                                       width="250">
                            <el-button type="text"
                                       slot="reference"
                                       size="mini">{{$t("m.pubilc.shanchu")}}
                            </el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>



            </el-table>
            <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                <el-pagination
                        background
                        :pageSize="pageSize"
                        :current-page.sync="pageNum"
                        @current-change="pageNumChanged"
                        layout="total,prev, pager, next"
                        :total="Result.total">
                </el-pagination>
            </el-row>
        </el-card>
        <couponEdit ref="couponEdit" @save="handleSave"></couponEdit>
        <grant ref="grant" @grantSave="grantSave"></grant>
    </div>
</template>

<script>
    import couponEdit from "./couponEdit";
    import grant from "./grant";
    import {getCouponPage,addCoupon,delCouponById,getCode,
        getCouponById,updateCoupon,invalidCoupon,releaseCoupon} from "request/api/coupon.js"
    export default {
        components: {
            couponEdit,grant
        },
        name: "ValuationList",
        data() {
            return {
                FormData: {
                    keyword: '',
                },

                pageSize: 10,
                pageNum: 1,
                Result: {},
                couponId:"",
            }
        },
        mounted() {
            this.getList();
        },
        methods: {

            getList() {
                let data = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    keyword: this.FormData.keyword,
                };
                let _this = this;
                getCouponPage(data).then(res => {
                    if(res.code==200){
                        _this.Result = res.data;
                    }
                })
            },

            //修改状态
            updateInfo(data) {
                let _this = this;
                invalidCoupon(data).then(res=>{
                    if(res.code == 200){
                        _this.$notify.success({
                            title:this.$t('m.pubilc.tips'),
                            message:this.$t('m.pubilc.tip_success')
                        });
                        _this.getList();
                    }
                });
            },
            //删除
            delInfo(data){
                let _this = this;
                delCouponById(data).then(res=>{
                    if(res.code == 200){
                        _this.$notify.success({
                            title:this.$t('m.pubilc.tips'),
                            message:this.$t('m.pubilc.tip_success')
                        });
                        _this.getList();
                    }
                })
            },
            //发放
            setInfo(data){
                this.$refs.grant.open()
                this.couponId = data.id
            },
            grantSave(data){
                let params = data
                params.couponId = this.couponId
                releaseCoupon(params).then(res=>{
                    if(res.code==200){
                        this.$message.success(res.message)
                        this.$refs.grant.close()
                        this.getList();
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //切换页数
            pageNumChanged(page) {
                this.pageNum = page;
                this.getList();
            },
            handleAdd(){
                this.$refs.couponEdit.open()
                getCode().then(res=>{
                    if(res.code==200){
                        this.$refs.couponEdit.FormData= {
                            merchantStatus:0,
                            sceneType:0,
                            templateStatus:0,
                        }
                        this.$refs.couponEdit.FormData.code = res.message;
                    }
                })
                this.$refs.couponEdit.dialogTitle = this.$t('m.coupon.couponAdd')
            },

            toEdit(item) {
                getCouponById({
                    id:item.id
                }).then(res=>{
                    if(res.code==200){
                        let result = res.data;
                        this.$refs.couponEdit.FormData.id = result.id;
                        this.$refs.couponEdit.FormData.name = result.name;
                        this.$refs.couponEdit.FormData.note = result.note;
                        this.$refs.couponEdit.FormData.count = result.count;
                        this.$refs.couponEdit.FormData.perLimit = result.perLimit;
                        this.$refs.couponEdit.FormData.amount = result.amount;
                        this.$refs.couponEdit.FormData.whereAmount = result.whereAmount;
                        this.$refs.couponEdit.FormData.code = result.code;
                        this.$refs.couponEdit.FormData.merchantStatus = result.merchantStatus;
                        this.$refs.couponEdit.FormData.merchantIds = result.merchantIds;
                        this.$refs.couponEdit.FormData.userId = result.merchantIds&&result.merchantIds.length>0?result.merchantIds.split(','):[];
                        this.$refs.couponEdit.FormData.sceneType = result.sceneType;
                        this.$refs.couponEdit.FormData.templateStatus = result.templateStatus;
                        this.$refs.couponEdit.FormData.templateIds = result.templateIds;
                        this.$refs.couponEdit.FormData.moduleId = result.templateIds&&result.templateIds.length>0?result.templateIds.split(','):[];
                        this.$refs.couponEdit.FormData.useNote = result.useNote;
                        this.$refs.couponEdit.FormData.enableTime = result.enableTime;
                        this.$refs.couponEdit.FormData.startEndTimes =[result.startTime,result.endTime];
                        this.$refs.couponEdit.dialogTitle = this.$t('m.coupon.couponEdit')
                        this.$refs.couponEdit.open()
                    }
                })
            },
            handleSave(){
                let data = JSON.parse(JSON.stringify(this.$refs.couponEdit.FormData));
                let params = {
                    code:data.code,
                    amount:data.amount,
                    whereAmount: data.whereAmount,
                    count: data.count,
                    perLimit: data.perLimit,
                    merchantStatus: data.merchantStatus,
                    sceneType: data.sceneType,
                    templateStatus: data.templateStatus,
                    name: data.name,
                    note: data.note,
                    enableTime: data.enableTime,
                    useNote: data.useNote,
                    startTime: data.startEndTimes[0],
                    endTime: data.startEndTimes[1],
                }
                if(data.userId&&data.userId.length>0){
                    params.merchantIds = data.userId.toString()
                }
                if(data.moduleId&&data.moduleId.length>0){
                    params.templateIds = data.moduleId.toString()
                }
                let _this = this;
                if(data.id){
                    params.id = data.id
                    updateCoupon(params).then(res=>{
                        if (res.code == 200){
                            _this.$notify.success({
                                title:this.$t('m.pubilc.tips'),
                                message:this.$t('m.pubilc.tip_success')
                            });
                            _this.$refs.couponEdit.close()
                            _this.getList()
                        }else {
                            _this.$notify.error({
                                title:this.$t('m.pubilc.tips'),
                                message:res.message
                            })
                        }
                    })
                }else{
                    addCoupon(params).then(res=>{
                        if (res.code == 200){
                            _this.$notify.success({
                                title:this.$t('m.pubilc.tips'),
                                message:this.$t('m.pubilc.tip_success')
                            });
                            _this.$refs.couponEdit.close()
                            _this.getList()
                        }else {
                            _this.$notify.error({
                                title:this.$t('m.pubilc.tips'),
                                message:res.message
                            })
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @import "../../../assets/style/table";

    .card {
        border: none;
        margin: 0 0 10px;
    }

    .coupon-card {
        width: 200px;
        height: 180px;
        background: #E5F4FE;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        border: none;
    }

    .coupon-card div {
        margin-bottom: 10px;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 0;
    }

    .img-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .img {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 40px;
        height: 40px;
    }
</style>
