<template>
    <el-dialog :title="dialogTitle"  width="780px" :visible.sync="dialogVisible" :close-on-click-modal="false">
        <el-form label-width="100px"
                 size="small"
                 :rules="rules">
<!--            规定优惠券不可编辑信息，只能失效或者删除-->
            <el-form-item :label="$t('m.coupon.youhuimingcheng')"
                          prop="name">
                <el-input :maxlength="100"
                          show-word-limit
                          :disabled="!!FormData.id"
                          v-model="FormData.name"/>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.beizhu')"
                          prop="note">
                <el-input :maxlength="200"
                          show-word-limit
                          type="textarea"
                          :disabled="!!FormData.id"
                          v-model="FormData.note"/>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.jinge')"
                          prop="amount">
                <el-input-number v-model.number="FormData.amount"
                                 :precision="2"
                                 :disabled="!!FormData.id"
                                 :min="0.01"
                                 :max="100000"/>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.tiaojian')"
                          prop="amount">
                <div style="display: flex;">
                    <el-input-number v-model.number="FormData.whereAmount"
                                     :disabled="!!FormData.id"
                                     :precision="2"
                                     :min="0"
                                     :max="100000"/>
                    <div style="margin-left: 20px;color: #999;font-size: 12px;">500表示订单满500方可使用，0表示不限制使用</div>
                </div>
                
            </el-form-item>
            <el-form-item :label="$t('m.coupon.fafangzongliang')"
                          prop="publishCount">
                <el-input-number v-model.number="FormData.count"
                                 :max="100000"
                                 :precision="0"
                                 :disabled="!!FormData.id"
                                 :min="1"/>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.xianlingshuliang')"
                          prop="perLimit">
                <el-input-number v-model="FormData.perLimit"
                                 :min="1"
                                 :precision="0"
                                 :disabled="!!FormData.id"
                                 :max="100000"/>
            </el-form-item>
<!--            卡券指定-->
            <el-form-item :label="$t('m.coupon.xianlingyonghu')">
                <el-radio-group v-model="FormData.merchantStatus" @change="selectUser" :disabled="!!FormData.id">
                    <el-radio :label="0">{{$t('m.coupon.alluser')}}</el-radio>
                    <el-radio :label="1">{{$t('m.coupon.oneuser')}}</el-radio>
                    <el-select v-model="FormData.userId" multiple collapse-tags :disabled="!!FormData.id"
                               :placeholder="$t('m.coupon.userPlace')" v-if="FormData.merchantStatus==1">
                        <el-option
                                v-for="item in userOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.shiyongchangjin')">
                <el-radio-group v-model="FormData.sceneType" :disabled="sceneDisabled||!!FormData.id">
                    <el-radio :label="0">{{$t('m.coupon.allchangjin')}}</el-radio>
                    <el-radio :label="1">{{$t('m.coupon.newUser')}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.xianlingmuban')">
                <el-radio-group v-model="FormData.templateStatus" :disabled="!!FormData.id">
                    <el-radio :label="0">{{$t('m.coupon.allmodule')}}</el-radio>
                    <el-radio :label="1">{{$t('m.coupon.onemodule')}}</el-radio>
                    <el-select v-model="FormData.moduleId" multiple collapse-tags :disabled="!!FormData.id"
                               :placeholder="$t('m.coupon.modulePlace')" v-if="FormData.templateStatus==1">
                        <el-option
                                v-for="item in moduleOptions"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-radio-group>
            </el-form-item>
            <!--            卡券指定End-->
            <el-form-item :label="$t('m.coupon.lingqushijian')"
                          prop="enableTime">
                <el-date-picker
                        type="datetime"
                        :disabled="!!FormData.id"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="FormData.enableTime">
                </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.shiyongshijian')"
                          prop="startEndTimes">
                <el-date-picker
                        v-model="FormData.startEndTimes"
                        type="datetimerange"
                        :disabled="!!FormData.id"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :range-separator="$t('m.coupon.zhi')"
                        :start-placeholder="$t('m.coupon.kaishishijian')"
                        :end-placeholder="$t('m.coupon.jieshushijiang')">
                </el-date-picker>
            </el-form-item>

            <el-form-item :label="$t('m.coupon.shiyongshuoming')"
                          prop="useNote">
                <el-input :maxlength="1000"
                          show-word-limit
                          :disabled="!!FormData.id"
                          type="textarea"
                          :autosize="{ minRows: 4, maxRows: 8}"
                          v-model="FormData.useNote"/>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.duihuancode')"
                          prop="code">
                <div @click="handleCopy(FormData.code)" style="cursor: pointer;">
                    {{FormData.code}}
                    <i class="el-icon-copy-document"></i>
                </div>
<!--                <el-input v-model="FormData.code" :readonly="!!FormData.id"/>-->
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="small">{{$t('m.pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="save" size="small">{{$t('m.pubilc.save')}}</el-button>
        </span>

    </el-dialog>
</template>

<script>
    import {getMerchantOption,
        getTemplateInfoOption} from "../../../request/api/coupon";
    export default {
        name: "couponEdit",
        data(){
            return{
                dialogTitle:"",
                dialogVisible:false,
                FormData: {
                    id:'',
                    name: '',
                    note: '',
                    count: 1,
                    perLimit: 1,
                    amount: 0,
                    info: '',
                    whereAmount:0,
                    startEndTimes: [],
                    enableTime: '',
                    userId:[],
                },
                rules:{
                    name:[],
                    note:[],
                    count:[],
                    perLimit:[],
                    amount:[],
                    info:[],
                    startEndTimes:[],
                    enableTime:[],
                },
                //用户列表
                userOptions:[],
                //模板列表
                moduleOptions:[],
                sceneDisabled:false,
            }
        },
        mounted(){
            this.getUserData()
            this.getModuleData()
        },
        methods:{
            //获取企业数据
            getUserData(){
                getMerchantOption().then(res=>{
                    console.log('190',res)
                    if(res.code==200){
                        this.userOptions = res.data
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //获取模板数据
            getModuleData(){
                getTemplateInfoOption().then(res=>{
                    console.log('201',res)
                    if(res.code==200){
                        this.moduleOptions = res.data
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //拷贝
            handleCopy(code){
                this.copy(code)
            },
            copy(data){
                let url = data;
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                console.log(oInput.value)
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message({
                    message: '复制成功',
                    type: 'success'
                });
                oInput.remove()
            },
            //关闭
            close(){
                this.dialogVisible=false
                this.FormData= {}
            },
            open(){
                this.dialogVisible=true
            },
        //    限领企业
            selectUser(){
                if(this.FormData.merchantStatus==1){
                    this.sceneDisabled = true
                    this.FormData.sceneType = 0
                }else{
                    this.sceneDisabled = false
                }
            },
        //    保存
            save(){
                if (!this.FormData.name){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingshezhiyouhuiquanname")
                    });
                    return;
                }
                if (!this.FormData.amount){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingshezhijine")
                    });
                    return;
                }
                if (!this.FormData.count){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingshezhifafang")
                    });
                    return;
                }
                if (!this.FormData.perLimit){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingshezhixianling")
                    });
                    return;
                }

                if(this.FormData.merchantStatus==undefined){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingxuanzeqiye")
                    });
                    return;
                }
                if(this.FormData.sceneType==undefined){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingxuanzechangjin")
                    });
                    return;
                }
                if(this.FormData.templateStatus==undefined){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingxuanzemuban")
                    });
                    return;
                }
                if (!this.FormData.enableTime){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.shezhilingqushijian")
                    });
                    return;
                }
                if (this.FormData.startEndTimes==undefined||this.FormData.startEndTimes.length == 0){
                    this.$notify.error({
                        title:this.$t('m.pubilc.tips'),
                        message: this.$t("m.coupon.qingshezhishiyongshijian")
                    });
                    return;
                }

                this.$emit('save')
            },
        }
    }
</script>

<style scoped>

</style>
