import {
    request
} from 'request/request.js'

/**
 * 优惠券列表
 *
 */
export const getCouponPage = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/getCouponPage',
        data: data
    })
}

/**
 * 添加
 */
export const addCoupon = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/addCoupon',
        data: data
    })
}

/**
 * 删除
 */
export const delCoupon = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/delCoupon',
        data: data
    })
}

/**
 *根据id获取详情
 */
export const getCouponById = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/getCouponById',
        data: data
    })
}

/**
 *更新
 */
export const updateCoupon = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/updateCoupon',
        data: data
    })
}

/**
 *失效
 */
export const invalidCoupon = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/invalidCoupon',
        data: data
    })
}

/**
 *删除
 */
export const delCouponById = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/delCoupon',
        data: data
    })
}

/**
 *核销列表
 */
export const getCouponUseRecordPage = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/getCouponUseRecordPage',
        data: data
    })
}

/**
 *生成兑换码
 */
export const getCode = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/getCode',
        data: data
    })
}







/**
 * 保存优惠券
 * @param data
 * @returns {*}
 * @author
 * @constructor
 */
export const SaveCoupon = (data) => {
    return request({
        method: 'post',
        url: '/mall/coupon/saveCoupon',
        data: data})
}
/**
 * 查询优惠券列表
 * @param data
 * @returns {*}
 * @author
 * @constructor
 */
export const CouponList = (data) => {
    return request({
        method: 'post',
        url: '/mall/coupon/list',
        data: data
    })
}

/**
 * 通ids查询优惠券列表
 * @param data
 * @returns {*}
 */
export const getListByIds = (data) => {
    return request({
        method: 'post',
        url: '/mall/coupon/getListByIds',
        data: data
    })
}

/**
 * 查询优惠券信息
 * @param data
 * @returns {*}
 * @author
 * @constructor
 */
export const CouponInfo = (data) => {
    return request({
        method: 'post',
        url: '/mall/coupon/info',
        data: data
    })
}

/**
 * 优惠券核销记录
 * @param data
 * @returns {*}
 * @author
 * @constructor
 */
export const CouponHistoryList = (data) => {
    return request({
        method: 'post',
        url: '/mall/minipro/couponHistory/queryAll',
        data: data
    })
}

/**
 * 获取商户下拉
 * @param data
 * @returns {*}
 */
export const getMerchantOption = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantOption',
        data: data
    })
}

/**
 * 获取模板信息下拉
 * @param data
 * @returns {*}
 */
export const getTemplateInfoOption = (data) => {
    return request({
        method: 'post',
        url: '/template/admin/getTemplateOption',
        data: data
    })
}

/**
 * 发放优惠券
 * @param data
 * @returns {*}
 */
export const releaseCoupon = (data) => {
    return request({
        method: 'post',
        url: '/merchant/admin/releaseCoupon',
        data: data
    })
}