<template>
    <el-dialog :title="dialogTitle"  width="500px" :visible.sync="dialogVisible" :close-on-click-modal="false">
        <el-form label-width="100px"
                 size="small">
            <el-form-item :label="$t('m.coupon.fafangbiaoti')">
                <el-input style="width: 200px;" v-model="FormData.title" :placeholder="$t('m.coupon.biaotiPlace')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.fafangtips')">
                <el-input style="width: 200px;" type="textarea" v-model="FormData.content" :placeholder="$t('m.coupon.neirongPlace')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.coupon.fafangqiye')">
                <el-select v-model="FormData.merchantId" :placeholder="$t('m.coupon.qiyePlace')">
                    <el-option
                            v-for="item in userOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close" size="small">{{$t('m.pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="save" size="small">{{$t('m.pubilc.save')}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getMerchantOption} from "../../../request/api/coupon";
    export default {
        name: "grant",
        data(){
            return{
                dialogTitle:"卡券发放",
                dialogVisible:false,
                userOptions:[],
                FormData:{
                    merchantId:'',
                    content:"",
                    title:"优惠券发放",
                },
            }
        },
        methods:{
            getMerchant(){
                getMerchantOption().then(res=>{
                    if(res.code==200){
                        this.userOptions = res.data
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            open(){
                this.dialogVisible = true
                this.getMerchant()
                this.FormData = {
                    merchantId:'',
                    content:"",
                    title:"优惠券发放",
                }
            },
            close(){
                this.dialogVisible = false
            },
            save(){
                console.log('save')
                if(this.FormData.merchantId.length==0){
                    this.$message.warning(this.$t('m.coupon.biaotiPlace'))
                    return
                }
                if(this.FormData.content.length==0){
                    this.$message.warning(this.$t('m.coupon.neirongPlace'))
                    return
                }
                if(this.FormData.title.length==0){
                    this.$message.warning(this.$t('m.coupon.qiyePlace'))
                    return
                }
                this.$emit('grantSave',this.FormData)
            },
        },
    }
</script>

<style scoped>

</style>